<template>
  <b-button
      class="button is-info is-hidden-mobile"
      @click="action"
      :disabled="disabled"
  >
    <span v-if="printer">
      {{ $t('createBarcode', {amount: stones.length}) }}
    </span>
    <span v-else>
      {{ $t('connectPrinter') }}
    </span>
  </b-button>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "PrintStones",
  props: {
    stones: Array
  },
  computed: {
    ...mapState(['printer']),
    disabled() {
      return this.printer && this.stones.length === 0
    }
  },
  methods: {
    ...mapActions([
      'printLabels',
      'connectDefaultPrinter'
    ]),
    action() {
      if (this.printer) this.printLabels(this.stones)
      else this.connectDefaultPrinter()
    }
  }
}
</script>

<style scoped>

</style>