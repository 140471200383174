import Vue from 'vue'

export  function milToCenti(value) {
    if (value === '') value = 0;
    return ("" + (value / 10)).replace('.', ',')
}

export default {
    methods: {
        squareMilToString(value) {
            return {
                value: ('' + Math.floor(value / 100) / 10000).replace('.', ','),
                unit: 'm<sup>2</sup>'
            }
        },
        centiToMil(value) {
            if (value === '') value = 0;
            value = value + '';
            let float = parseFloat(value.replace(',', '.'));
            return Math.floor(float * 10);
        },
        milToCenti
    }

};

let filter = function (text, length, clamp) {
    clamp = clamp || '...';
    let node = document.createElement('div');
    node.innerHTML = text;
    let content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
