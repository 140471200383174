<template>
    <div>
        <template v-if="openApplication">
            <router-view/>
            <footer class="footer">
                <div class="content has-text-centered">
                    <p>
                        <strong>{{username}}</strong> <a @click="logout">{{$t('logout')}}</a>
                    </p>
                </div>
            </footer>
        </template>
        <b-modal
                :active.sync="needLogin"
                aria-role="dialog"
                has-modal-card
                aria-modal
                :can-cancel="false">
            <login @success="loginSuccess"/>
        </b-modal>
    </div>
</template>

<script>
    import {HTTP} from '@/http-common'
    import Login from "./components/login";

    export default {
        name: 'App',
        components: {Login},
        data() {
            return {
                openApplication: false,
                needLogin: false,
                username: ''
            }
        },
        mounted() {
            const loadingComponent = this.$buefy.loading.open();

            HTTP().get('logincheck').then(() => {
                loadingComponent.close();
                this.openApplication = true
            }).catch(() => {
                loadingComponent.close();
                this.needLogin = true;
            })
        },
        methods: {
            loginSuccess() {
                this.openApplication = true;
                this.needLogin = false;
            },
            logout() {
                HTTP().post('logout').then(() => {
                    this.needLogin = true;
                });
            }
        }
    }
</script>
<style>
</style>
