<template>
    <div>
        <div class="card">
            <div class="card-image" @click="show = true">
                <figure class="image is-3by2" :style="thumbStyle">
                </figure>
            </div>
            <div class="card-content" v-if="image.description">
                <div class="content">
                    {{image.description}}
                </div>
            </div>
            <footer class="card-footer">
                <a class="card-footer-item" @click="edit">{{$t('edit')}}</a>
                <a class="card-footer-item" @click="confirmDelete">{{$t('delete')}}</a>
            </footer>
        </div>
        <b-modal :active.sync="show">
            <div class="image">
                <img :src="image['image']">
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {HTTP} from '../http-common'

    export default {
        name: "StoneImage",
        props: ['image'],
        data() {
            return {
                show: false
            }
        },
        computed: {
            thumbStyle() {
                return {backgroundImage: 'url("' + this.image['thumbnail'] + '")'};
            }
        },
        methods: {
            confirmDelete() {
                this.$buefy.dialog.confirm({
                    title: this.$t('deleteImage'),
                    message: this.$t('deleteImageMessage'),
                    confirmText: this.$t('deleteImage'),
                    cancelText: this.$t('cancel'),
                    type: 'is-danger',
                    onConfirm: () => this.delete()
                })
            },
            delete() {
                HTTP().delete('stones/image/' + this.image.id).then(() => {
                    this.$emit('refresh')
                })
            },
            edit() {
                this.$buefy.dialog.prompt({
                    message: this.$t('imageCaption'),
                    inputAttrs: {
                        type: 'text',
                        value: this.image['description'],
                        maxlength: 250
                    },
                    trapFocus: true,
                    cancelText: this.$t('cancel'),
                    confirmText: this.$t('save'),
                    onConfirm: (value) => {
                        HTTP().put('stones/image/' + this.image.id, {description: value}).then(() => {
                            this.$emit('refresh')
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    figure {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        cursor: pointer;
    }
</style>