import MainMenu from './components/MainMenu'
import StoneEdit from "./components/StoneEdit";
import StoneTypeEdit from "./components/StoneTypeEdit";
import ScanBarcode from "./components/ScanBarcode";

export default [
    {
        path: '/',
        component: MainMenu,
    },
    {
        path: '/:position',
        component: MainMenu,
        props: true,
        name: 'main-menu'
    },
    {
        path: '/stone/create',
        component: StoneEdit,
        name: 'stone-create'
    },
    {
        path: '/stone/edit/:stone_id',
        component: StoneEdit,
        props: true,
        name: 'stone-edit'
    },
    {
        path: '/stonetype/create',
        component: StoneTypeEdit,
        name: 'stone-type-create'
    },
    {
        path: '/stonetype/edit/:stone_type_id',
        component: StoneTypeEdit,
        props: true,
        name: 'stone-type-edit'
    },
    {
        path: '/scan',
        component: ScanBarcode,
        name: 'scan-barcode'
    },
]