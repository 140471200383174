<template>
    <section id="app">
        <div class="container">
            <b-tabs v-model="currentPosition" style="margin-top: 40px">
                <b-tab-item :label="$t('stones')" value="stones">
                    <stone-list/>
                </b-tab-item>
                <b-tab-item :label="$tc('stoneType', 2)" value="stonetypes">
                    <stone-type-list/>
                </b-tab-item>
            </b-tabs>
        </div>
    </section>
</template>

<script>
    import StoneList from "./StoneList";
    import StoneTypeList from "./StoneTypeList";

    export default {
        name: "MainMenu",
        components: {StoneTypeList, StoneList},
        props: ['position'],
        data() {
            return {
                currentPosition: 'stones'
            }
        },
        mounted() {
            if (!this.position) {
                this.updatePosition();
            } else {
                this.currentPosition = this.position;
            }
        },
        watch: {
            currentPosition() {
                this.updatePosition();
            }
        },
        methods: {
            updatePosition() {
                this.$router.push({
                    name: 'main-menu',
                    params: {
                        position: this.currentPosition
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>