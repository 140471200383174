<template>
  <section class="section" v-if="!loading">
    <div class="container" v-if="error === 404">
      <h1 class="h1">
        Stein mit der ID: {{ stone_id }} kann nicht gefunden werden.
      </h1>

      <router-link :to="{name: 'main-menu', params:{position: 'stones'}}">
        Zurück zum Hauptmenü
      </router-link>
    </div>
    <div class="container" v-else>
      <h1 class="title" v-if="stone_id">
        {{ $t('editStone', {id: stone_id}) }}
      </h1>
      <h1 class="title" v-else>
        {{ $t('createNewStone') }}
      </h1>
      <div class="buttons">
        <button class="button is-warning" v-on:click="backToList">Abbrechen</button>
        <template v-if="stone_id">
          <button class="button is-primary" v-on:click="updateStone" v-bind:disabled="!savable">
            {{ $t('save') }}
          </button>
          <print-stones :stones="[stone]"/>
          <button class="button is-danger" v-on:click="deleteStone">
            {{ $t('delete') }}
          </button>
        </template>
        <template v-else>
          <button class="button is-primary" v-on:click="createStone(false)" v-bind:disabled="!savable">
            {{ $t('create') }}
          </button>
          <button class="button is-primary" v-on:click="createStone(true)" v-bind:disabled="!savable">
            {{ $t('createAndGenerateLabel') }}
          </button>
        </template>
      </div>
      <b-field :label="$tc('stoneType', 1)" v-bind:message="stone_type_name">
        <b-autocomplete
            v-model="stone_type_string"
            :data="stone_types"
            :loading="loading_stone_types"
            :open-on-focus="true"
            placeholder=""
            clearable
            field="keyword"
            class="stone_type_dropdown"
            @input="getStoneTypes()"
            @focus="getStoneTypes()"
            @select="selectStoneType">
          <template slot-scope="props">
            <strong style="display: block">
              {{ props.option.keyword }}
            </strong>
            <span>
                            ({{ props.option.name }})
                        </span>
          </template>
          <template slot="empty">Steintyp nicht gefunden</template>
        </b-autocomplete>
      </b-field>
      <b-field :label="$t('height')"/>
      <b-field>
        <b-input v-model="heightCenti" expanded/>
        <p class="control">
          <span class="button is-static">cm</span>
        </p>
      </b-field>
      <b-field :label="$t('width')"/>
      <b-field>
        <b-input v-model="widthCenti" expanded/>
        <p class="control">
          <span class="button is-static">cm</span>
        </p>
      </b-field>
      <template v-if="stone_type">
        <b-field :label="$t('thickness')"/>
        <b-field>
          <b-input v-model="thicknessCenti" expanded disabled/>
          <p class="control">
            <span class="button is-static">cm</span>
          </p>
        </b-field>
      </template>
      <b-field :label="$t('area')"/>
      <b-field>
        <b-input v-model="squareMilToString(area)['value']" expanded disabled/>
        <p class="control">
          <span class="button is-static" v-html="squareMilToString(area)['unit']"></span>
        </p>
      </b-field>
      <b-field :label="$t('squareMeterPrice')"/>
      <b-field>
        <b-input v-model="square_meter_price_string" expanded/>
        <p class="control">
                    <span class="button is-static">
                        €
                    </span>
        </p>
      </b-field>
      <b-field :label="$t('value')"/>
      <b-field>
        <b-input v-model="value_string" expanded disabled/>
        <p class="control">
                    <span class="button is-static">
                        €
                    </span>
        </p>
      </b-field>
      <b-field :label="$t('residualStone')">
        <b-checkbox v-model="residual_stone">
          {{ $t('residualStone') }}
        </b-checkbox>
      </b-field>
      <b-field :label="$t('storagePlace')">
        <b-input v-model="storage_place"/>
      </b-field>
      <b-field :label="$t('comment')">
        <b-input type="textarea" v-model="comment"/>
      </b-field>
      <b-field :label="$t('creationDate')" v-if="created">
        <b-input :value="$d(new Date(created), 'default')" disabled/>
      </b-field>
      <b-field :label="$t('creator')" v-if="creator">
        <b-input :value="creator" disabled/>
      </b-field>
      <b-field :label="$t('lastEditDate')" v-if="modified">
        <b-input :value="$d(new Date(modified), 'default')" disabled/>
      </b-field>
      <b-field :label="$t('lastEditor')" v-if="last_editor">
        <b-input :value="last_editor" disabled/>
      </b-field>
      <div class="buttons">
        <template v-if="stone_id">
          <button class="button is-primary" @click="updateStone" :disabled="!savable">
            {{ $t('save') }}
          </button>
          <print-stones :stones="[raw_stone_object]"/>
          <button class="button is-danger" @click="deleteStone">
            {{ $t('delete') }}
          </button>
          <button class="button is-primary" @click="confirmDuplicate">
            {{ $t('duplicate') }}
          </button>
        </template>
        <template v-else>
          <button class="button is-primary" @click="createStone(false)" :disabled="!savable">
            {{ $t('create') }}
          </button>
          <button class="button is-primary" @click="createStone(true)" :disabled="!savable || !printer">
            {{ $t('createAndGenerateLabel') }}
          </button>
        </template>
        <b-button type="is-success" @click="addImage" :disabled="!savable">
          {{ $t('addImage') }}
        </b-button>
      </div>
      <div class="wrapper">
        <div v-for="image in images" v-bind:key="image.id">
          <StoneImage v-bind:image="image" v-on:refresh="fetchData()"/>
        </div>
      </div>
      <b-modal :active.sync="add_image_modal">
        <add-stone-image :stone_id="stone_id" @refresh="fetchData()" @close="add_image_modal=false"/>
      </b-modal>
    </div>
  </section>
</template>

<script>
import {HTTP} from '@/http-common'
import AddStoneImage from "./AddStoneImage";
import StoneImage from "./StoneImage";
import PrintStones from "@/components/PrintStones.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "StoneEdit",
  components: {PrintStones, StoneImage, AddStoneImage},
  props: ['stone_id'],
  data() {
    return {
      stone_type_string: '',
      loading: true,
      stone_types: [],
      loading_stone_types: true,
      stone_type: false,
      height: 0,
      width: 0,
      images: [],
      residual_stone: false,
      comment: '',
      storage_place: '',
      square_meter_price: 0,

      raw_stone_object: false,

      created: false,
      modified: false,
      creator: false,
      last_editor: false,

      error: false,
      add_image_modal: false
    }
  },
  mounted() {
    if (this.stone_id) {
      this.fetchData()
    } else {
      this.loading = false;
    }
  },
  computed: {
    ...mapState(['printer']),
    area() {
      return this.height * this.width;
    },
    savable() {
      return this.stone_type && this.stone_type_string !== '';
    },
    widthCenti: {
      get() {
        return this.milToCenti(this.width);
      },
      set(value) {
        this.width = this.centiToMil(value);
      }
    },
    heightCenti: {
      get() {
        return this.milToCenti(this.height);
      },
      set(value) {
        this.height = this.centiToMil(value);
      }
    },
    thicknessCenti: {
      get() {
        return this.milToCenti(this.stone_type.thickness);
      }
    },
    square_meter_price_string: {
      get() {
        return ("" + this.square_meter_price).replace('.', ',');
      },
      set(value) {
        if (value === '') {
          this.square_meter_price = 0;
        } else {
          this.square_meter_price = parseFloat(value.replace(',', '.'));
        }
      }
    },
    stone() {
      return {
        stone_type: this.stone_type.id,
        height: this.height,
        width: this.width,
        residual_stone: this.residual_stone,
        comment: this.comment,
        storage_place: this.storage_place,
        square_meter_price: this.square_meter_price
      }
    },
    stone_type_name() {
      if (this.stone_type) return this.stone_type.name;
      else return "";
    },
    value_string() {
      return ("" + this.value).replace('.', ',')
    },
    value() {
      return (((this.height * this.width) / 1000000) * this.square_meter_price).toFixed(2)
    }
  },
  methods: {
    ...mapActions([
      'printLabels',
      'connectDefaultPrinter'
    ]),
    fetchData() {
      HTTP().get('stones/id/' + this.stone_id).then(response => {
        this.raw_stone_object = response.data;
        this.stone_type = response.data.stone_type;
        this.height = response.data.height;
        this.width = response.data.width;
        this.residual_stone = response.data.residual_stone;
        this.comment = response.data.comment;
        this.images = response.data.images;
        this.storage_place = response.data.storage_place;
        this.square_meter_price = parseFloat(response.data.square_meter_price);

        this.created = response.data.created ? response.data.created : false;
        this.modified = response.data.modified ? response.data.modified : false;
        this.creator = response.data.creator_name ? response.data.creator_name : false;
        this.last_editor = response.data.last_editor_name ? response.data.last_editor_name : false;

        this.stone_type_string = this.stone_type.keyword;
        this.loading = false;
      }).catch(error => {
        window.console.log(error.response);
        this.error = error.response.status;
        this.loading = false;
      });
    },
    getStoneTypes() {
      this.loading_stone_types = true;
      let url;
      if (this.stone_type_string) {
        url = 'stonetypes/search/' + this.stone_type_string
      } else {
        url = 'stonetypes'
      }

      HTTP().get(url).then(response => {
        this.stone_types = response.data.results;
        this.loading_stone_types = false;
      });
    },
    selectStoneType(option) {
      this.stone_type = option;
      this.square_meter_price = option.default_square_meter_price;
    },
    save(callback) {
      HTTP().post('stones', this.stone).then(response => {
        if (response.status === 200) {
          this.$buefy.toast.open({
            message: this.$t('stoneCreatedSuccess', {type: response.data.stone_type.keyword}),
            type: 'is-success'
          });
          callback(response);
        } else {
          this.$buefy.toast.open({
            message: this.$t('stoneCreatedError'),
            type: 'is-danger'
          })
        }
      });
    },
    createStone(label = false) {
      this.save(response => {
        this.backToList();
        if (label && this.printer) {
          this.printLabels([response.data])
        }
      });
    },
    updateStone() {
      HTTP().put('stones/id/' + this.stone_id, this.stone).then(() => {
        this.backToList();
      });
    },
    deleteStone() {
      this.$buefy.dialog.confirm({
        title: this.$t('deleteStone'),
        message: this.$t('deleteStoneMessage'),
        confirmText: this.$t('deleteStone'),
        cancelText: this.$t('cancel'),
        type: 'is-danger',
        onConfirm: () => {
          HTTP().delete('stones/id/' + this.stone_id).then(() => {
            this.backToList();
          });
        }
      });
    },
    backToList() {
      this.$router.push({
        name: 'main-menu',
        params: {position: 'stones'}
      });
    },
    addImage() {
      if (this.stone_id) {
        this.add_image_modal = true
      } else {
        this.save(response => {
          this.$router.push({
            name: 'stone-edit',
            params: {stone_id: response.data.id}
          });
          this.add_image_modal = true
        });
      }
    },
    duplicate(count) {
      let body = {
        stone: this.stone_id,
        count
      }
      HTTP().post('stones/duplicate', body).then(response => {
        let html = "<div class='content'>" + this.$t('created_ids') + "<ul>";
        let length = response.data['ids'].length
        for (let i = 0; i < length; i++) html += "<li>" + response.data['ids'][i] + "</li>";
        html += "</ul></div>"

        this.$buefy.dialog.confirm({
          title: this.$t('stoneDuplicationSuccess'),
          message: html,
          cancelText: this.$t('close'),
          confirmText: this.$t('generateLabel'),
          type: 'is-success',
          onConfirm: () => this.generateLabelPdf(response.data['ids'])

        })
      })
    },
    confirmDuplicate() {
      this.$buefy.dialog.prompt({
        message: this.$t('duplicateAmount'),
        inputAttrs: {
          type: 'number',
          placeholder: 1,
          value: 1,
          min: 1
        },
        trapFocus: true,
        onConfirm: this.duplicate,
        confirmText: this.$t('duplicateStone'),
        cancelText: this.$t('cancel'),
      })
    }
  }
}
</script>

<style>
.wrapper > div {
  width: calc(25% - 20px);
  min-width: 250px;
  display: inline-block;
  vertical-align: top;
  margin: 10px;
}

.dropdown-content > .dropdown-item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-content > .dropdown-item:first-child {
  border: 0
}
</style>