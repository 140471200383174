export default {
    save: 'Speichern',
    cancel: 'Abbrechen',
    delete: 'Löschen',
    create: 'erstellen',
    comment: 'Kommentar',
    edit: 'Bearbeiten',
    id: 'ID',
    type: 'Typ',
    dimensions: 'Maße',
    details: 'Details',
    name: 'Name',
    search: 'Suche',
    close: 'schließen',

    stones: 'Steine',
    stone_count: 'Tafeln',
    fullStones: 'Rohtafeln',
    stoneType: 'Steintyp | Steintypen',
    editStone: 'Stein {id} Bearbeiten',
    createNewStone: 'Neuen Stein anlegen',
    generateLabel: 'Label generieren',
    createAndGenerateLabel: 'Erstellen und Label generieren',
    addStone: 'Stein hinzufügen',
    scanBarcode: 'Barcode Scannen',
    createBarcode: 'Barcodes Erstellen ({amount})',
    createExcel: 'Excel erstellen',
    resetSelection: 'Selektion aufheben',
    noStoneFound: 'Keine Steine gefunden',
    deleteStone: 'Stein löschen',
    deleteStoneMessage: 'Wollen Sie wirklich diesen Stein löschen?',
    deleteStoneType: 'Steintyp löschen',
    deleteStoneTypeMessage: 'Wollen Sie wirklich diesen Steintyp löschen?',

    height: 'Höhe',
    width: 'Breite',
    thickness: 'Stärke',
    area: 'Fläche',
    totalArea: 'Gesamtfläche',
    residualStone: 'Reststein',

    creationDate: 'Erstelldatum',
    creator: 'Ersteller',
    lastEditDate: 'letztes bearbeitungsdatum',
    lastEditor: 'letzter Bearbeiter',

    addImage: 'Bild hinzufügen',
    stoneCreatedSuccess: 'Stein mit Typ: {type} erstellt.',
    stoneCreatedError: 'Fehler: Stein konnte nicht erstellt werden',

    deleteImage: 'Bild löschen',
    deleteImageMessage: 'Wollen Sie wirklich das Bild löschen?',
    imageCaption: 'Bildbeschreibung',

    editStoneType: 'Steintyp {name} bearbeiten',
    createNewStoneType: 'Neuen Steintyp anlegen',
    keyword: 'Suchname',
    minAmount: 'Mindestmenge RT',
    addStoneType: 'Steintyp Hinzufügen',
    noStoneTypeFound: 'Keine Steintypen gefunden',

    wrongPassword: 'Das Passwort oder der Benutzername ist falsch.',
    username: 'Benutzername',
    password: 'Passwort',
    login: 'Login',
    logout: 'Abmelden',
    loginAction: 'Einloggen',

    clickOrDropImage: 'Klicken oder Bilder hier hinein ziehe',

    onlyShowBelowMinimum: 'Zeige Typen die die Mindestmenge unterschreiten',
    storagePlace: 'Lagerplatz',
    squareMeterPrice: 'Quadratmeterpreis EK',
    defaultSquareMeterPrice: 'Standard Quadratmeterpreis EK',
    totalValue: 'Gesamtwert EK',
    value: 'EK Wert',

    duplicate: 'Duplizieren',
    duplicateAmount: 'Wie häufig soll dieser Stein dupliziert werden?',
    duplicateStone: 'durchführen',
    stoneDuplicationSuccess: 'Vorgang erfolgreich abgeschlossen',
    created_ids: 'Erstellte Steine:',
    connectPrinter: 'Drucker verbinden'
}