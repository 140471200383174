<template>
  <div>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <b-button tag="router-link"
                    type="is-success"
                    :to="{name: 'stone-create'}">
            {{ $t('addStone') }}
          </b-button>
        </div>
        <div class="level-item">
          <b-button :to="{name: 'scan-barcode'}" type="is-warning" tag="router-link">
            {{ $t('scanBarcode') }}
          </b-button>
        </div>
        <div class="level-item" v-if="!mobile">
          <print-stones :stones="checked_rows"/>
        </div>
        <div class="level-item">
          <a href="/api/stones/excel" class="button is-primary" download>
            {{ $t('createExcel') }}
          </a>
        </div>
        <div class="level-item" v-if="checked_rows.length > 0">
          <b-button class="is-warning" @click="checked_rows = []">
            {{ $t('resetSelection') }}
          </b-button>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-autocomplete
                v-model="search"
                :data="stone_types"
                :loading="loading_stone_types"
                :open-on-focus="true"
                placeholder="suche"
                clearable
                field="keyword"
                class="stone_type_dropdown"
                @typing="getStoneTypes"
                @select="onPageChange(1)">
              <template slot-scope="props">
                <strong style="display: block">
                  {{ props.option.keyword }}
                </strong>
                <span>({{ props.option.name }})</span>
              </template>
              <template #empty>Steintyp nicht gefunden</template>
            </b-autocomplete>
            <p class="control">
              <b-button class="button is-primary" v-on:click="onPageChange(1)">
                {{ $t('search') }}
              </b-button>
            </p>
          </b-field>
        </div>
      </div>
    </div>
    <b-table
        :data="stones"
        :loading="loading"
        paginated
        backend-pagination
        :checkable="!mobile"
        :checked-rows.sync="checked_rows"
        :total="total"
        :per-page="50"
        :striped="true"
        :hoverable="true"
        @page-change="onPageChange"
    >

      <b-table-column field="id" :label="$t('id')" width="40" v-slot="props" numeric>
        {{ props.row.id }}
      </b-table-column>
      <b-table-column field="type" :label="$t('type')" v-slot="props">
        {{ props.row.stone_type.keyword }}
      </b-table-column>
      <b-table-column field="dimensions" :label="$t('dimensions')" v-slot="props">
        {{ milToCenti(props.row.height) }}cm x {{ milToCenti(props.row.width) }}cm
      </b-table-column>
      <b-table-column field="storage_place" :label="$t('storagePlace')" v-slot="props">
        {{ props.row.storage_place }}
      </b-table-column>
      <b-table-column field="last_modified" :label="$t('lastEditDate')" v-slot="props">
        {{ $d(new Date(props.row.modified), 'default') }}
      </b-table-column>
      <b-table-column field="details" label="" v-slot="props">
        <router-link :to="{name: 'stone-edit', params:{stone_id: props.row.id}}"
                     class="button is-primary is-small is-pulled-right">
          {{ $t('details') }}
        </router-link>
      </b-table-column>
      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('noStoneFound') }}</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>

</template>

<script>
import {HTTP} from '@/http-common'
import PrintStones from "@/components/PrintStones.vue";

export default {
  name: "StoneList",
  components: {PrintStones},
  data() {
    return {
      stones: [],
      total: 0,
      loading: false,
      page: 1,
      search: '',
      checked_rows: [],
      stone_types: [],
      loading_stone_types: false
    }
  },
  mounted() {
    this.loadStones();
  },
  computed: {
    mobile() {
      return screen.width <= 768;
    }
  },
  methods: {
    loadStones() {
      this.loading = true;
      let url;
      if (this.search !== '') {
        url = 'stones/search/' + this.search
      } else {
        url = 'stones'
      }

      HTTP().get(url + '?page=' + this.page).then(response => {
        this.stones = response.data.results;
        this.total = response.data.count;
        this.loading = false;
      })
    },
    onPageChange(page) {
      this.page = page;
      this.loadStones();
    },
    searchKeyPressed: function (e) {
      if (e.keyCode === 13) {
        this.onPageChange(1);
      }
    },
    getStoneTypes(search = false) {
      if (!search) search = this.search

      this.loading_stone_types = true;
      let url;
      if (search) url = 'stonetypes/search/' + search;
      else url = 'stonetypes';

      HTTP().get(url).then(response => {
        this.stone_types = response.data.results;
        this.loading_stone_types = false;
      });
    },
  }
}
</script>

<style scoped>

</style>