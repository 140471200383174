<template>
    <section class="section" v-if="!loading">
        <div class="container">
            <h1 class="title" v-if="stone_type_id">
                {{$t('editStoneType', {name: stone_type_id})}}
            </h1>
            <h1 class="title" v-else>
                {{$t('createNewStoneType')}}
            </h1>
            <b-field :label="$t('name')">
                <b-input v-model="stoneType.name"/>
            </b-field>
            <b-field :label="$t('keyword')">
                <b-input v-model="stoneType.keyword"/>
            </b-field>
            <b-field :label="$t('thickness')"/>
            <b-field>
                <b-input v-model="thicknessCenti" expanded/>
                <p class="control">
                    <span class="button is-static">cm</span>
                </p>
            </b-field>
            <b-field :label="$t('minAmount')">
                <b-input v-model="stoneType.minimum_amount"/>
            </b-field>
            <b-field :label="$t('defaultSquareMeterPrice')"/>
            <b-field>
                <b-input v-model="square_meter_price_string" expanded/>
                <p class="control">
                    <span class="button is-static">€</span>
                </p>
            </b-field>
            <b-field :label="$t('stone_count')" v-if="stone_type_id">
                <b-input v-model="stoneType.stone_count" disabled/>
            </b-field>
            <b-field :label="$t('totalValue')"/>
            <b-field>
                <b-input v-model="total_value_string" expanded disabled=""/>
                <p class="control">
                    <span class="button is-static">€</span>
                </p>
            </b-field>
            <b-field :label="$t('creationDate')" v-if="stoneType.created">
                <b-input :value="$d(new Date(stoneType.created), 'default')" disabled/>
            </b-field>
            <b-field :label="$t('creator')" v-if="stoneType.creator_name">
                <b-input :value="stoneType.creator_name" disabled/>
            </b-field>
            <b-field :label="$t('lastEditDate')" v-if="stoneType.modified">
                <b-input :value="$d(new Date(stoneType.modified), 'default')" disabled/>
            </b-field>
            <b-field :label="$t('lastEditor')" v-if="stoneType.last_editor_name">
                <b-input :value="stoneType.last_editor_name" disabled/>
            </b-field>
            <div class="buttons">
                <button class="button is-warning" v-on:click="backToList">
                    {{$t('cancel')}}
                </button>
                <template v-if="stone_type_id">
                    <button class="button is-primary" v-on:click="updateStone" v-bind:disabled="!savable">
                        {{$t('save')}}
                    </button>
                    <button class="button is-danger" v-on:click="deleteStone"
                            v-bind:disabled="stoneType.stone_count !== 0">
                        {{$t('delete')}}
                    </button>
                </template>
                <template v-else>
                    <button class="button is-primary" v-on:click="createStone" v-bind:disabled="!savable">
                        {{$t('create')}}
                    </button>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
    import {HTTP} from '@/http-common'

    export default {
        name: "StoneTypeEdit",
        props: ['stone_type_id'],
        data() {
            return {
                loading: false,
                stoneType: {
                    name: '',
                    keyword: '',
                    thickness: 0,
                    minimum_amount: 0,
                    default_square_meter_price: 0.0
                }
            }
        },
        mounted() {
            if (this.stone_type_id) {
                this.loading = true;
                HTTP().get('stonetypes/id/' + this.stone_type_id).then(response => {
                    this.stoneType = response.data;
                    this.loading = false;
                });
            }
        },
        computed: {
            thicknessCenti: {
                get() {
                    return this.milToCenti(this.stoneType.thickness);
                },
                set(value) {
                    this.stoneType.thickness = this.centiToMil(value);
                }
            },
            savable() {
                return this.stoneType.name !== '' &&
                    this.stoneType.keyword !== '' &&
                    this.stoneType.thickness > 0 &&
                    this.stoneType.default_square_meter_price > 0
            },
            square_meter_price_string: {
                get() {
                    return ("" + this.stoneType.default_square_meter_price).replace('.', ',');
                },
                set(value) {
                    if (value === '') {
                        this.stoneType.default_square_meter_price = 0;
                    } else {
                        this.stoneType.default_square_meter_price = parseFloat(value.replace(',', '.'));
                    }
                }
            },
            total_value_string() {
                return ("" + this.stoneType.total_value).replace('.', ',')
            }
        },
        methods: {
            backToList() {
                this.$router.push({
                    name: 'main-menu',
                    params: {position: 'stonetypes'}
                });
            },
            createStone() {
                HTTP().post('stonetypes', this.stoneType).then(() => {
                    this.backToList();
                });
            },
            updateStone() {
                HTTP().put('stonetypes/id/' + this.stone_type_id, this.stoneType).then(() => {
                    this.backToList();
                });
            },
            deleteStone() {

                this.$buefy.dialog.confirm({
                    title: this.$t('deleteStoneType'),
                    message: this.$t('deleteStoneTypeMessage'),
                    confirmText: this.$t('deleteStoneType'),
                    cancelText: this.$t('cancel'),
                    type: 'is-danger',
                    onConfirm: () => {
                        HTTP().delete('stonetypes/id/' + this.stone_type_id).then(() => {
                            this.backToList();
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>