<template>
  <div>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <router-link :to="{name: 'stone-type-create'}" class="button is-success">
            {{ $t('addStoneType') }}
          </router-link>
        </div>
        <div class="level-item">
          <b-switch v-model="onlyShowBelowMinimum">
            {{ $t('onlyShowBelowMinimum') }}
          </b-switch>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <input class="input" :placeholder="$t('keyword')" v-model="search" @keyup="searchKeyPressed"/>
            <p class="control">
              <b-button class="button is-primary" v-on:click="onPageChange(1)">
                {{ $t('search') }}
              </b-button>
            </p>
          </b-field>
        </div>
      </div>
    </div>
    <b-table
        :data="stone_types"
        :loading="loading"
        paginated
        backend-pagination
        :total="total"
        :per-page="50"
        :striped="true"
        :hoverable="true"
        @page-change="onPageChange"

        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
    >

      <b-table-column field="keyword" :label="$t('keyword')" sortable v-slot="props">
        {{ props.row.keyword }}
      </b-table-column>
      <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="stone_count" :label="$t('stone_count')" sortable v-slot="props">
        {{ props.row.stone_count }}
      </b-table-column>
      <b-table-column field="stone_full_count" :label="$t('fullStones')" sortable v-slot="props">
        {{ props.row.stone_full_count }}
      </b-table-column>
      <b-table-column :label="$t('minAmount')" field="minimum_amount"
                      sortable
                      v-slot="props"
      >
        <div class="danger-wrapper" :class="{'is-danger': props.row.minimum_amount > props.row.stone_full_count}">
          {{ props.row.minimum_amount }}
        </div>
      </b-table-column>
      <b-table-column :label="$t('totalArea')" field="total_area" sortable v-slot="props">
        {{ squareMilToString(props.row.total_area)['value'] }} <span
          v-html="squareMilToString(props.row.total_area)['unit']"/>
      </b-table-column>
      <b-table-column label="" v-slot="props">
        <router-link :to="{name: 'stone-type-edit', params:{stone_type_id:props.row.id}}"
                     class="button is-small is-primary is-pulled-right">
          {{ $t('details') }}
        </router-link>
      </b-table-column>
      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('noStoneTypeFound') }}</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import {HTTP} from '@/http-common'

export default {
  name: "StoneTypeList",
  data() {
    return {
      search: '',
      stone_types: [],
      loading: true,
      sortField: 'keyword',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      onlyShowBelowMinimum: false,
      total: 0,
      page: 1
    }
  },
  mounted() {
    this.loadStoneTypes()
  },
  watch: {
    onlyShowBelowMinimum() {
      this.loadStoneTypes();
    }
  },
  methods: {
    loadStoneTypes() {
      this.loading = true;
      let url;

      if (this.search !== '') {
        url = 'stonetypes/search/' + this.search;
      } else {
        url = 'stonetypes';
      }

      url += "?page=" + this.page + "&sort_by=" + this.sortField + "&sort_order=" + this.sortOrder + "&not_enough_stones=" + this.onlyShowBelowMinimum

      HTTP().get(url).then(response => {
        this.total = response.data.count;
        this.stone_types = response.data.results;
        this.loading = false;
      });
    },
    onPageChange(page) {
      this.page = page;
      this.loadStoneTypes();
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadStoneTypes()
    },
    searchKeyPressed: function (e) {
      if (e.keyCode === 13) {
        this.onPageChange(1);
      }
    },
  }
}
</script>

<style scoped>
.danger-wrapper {
  width: 100%;
  height: 100%;
  display: block;
}
</style>