import Vue from 'vue'
import i18n from './translations'
import Buefy from 'buefy'
import App from './App.vue'
import 'buefy/dist/buefy.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import routes from './routes'
import store from "./store";
import mixin from "./mixins";
import ImageUploader from 'vue-image-upload-resize'
import "../public/BrowserPrint-3.0.216.min"

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.mixin(mixin);
Vue.use(ImageUploader);
export const router = new VueRouter({
    routes,
    modes: 'history',
    linkActiveClass: 'is-active'
});

new Vue({
    render: h => h(App),
    i18n,
    router,
    store,
}).$mount('#app');