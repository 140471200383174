<template>
    <form action="">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ $t('login') }}</p>
            </header>
            <section class="modal-card-body">
                <b-message title="Login fehlgseschlagen" type="is-danger" aria-close-label="Close message" v-if="error">
                    {{ $t('wrongPassword') }}
                </b-message>
                <b-field :label="$t('username')">
                    <b-input
                        type="text"
                        :placeholder="$t('username')"
                        v-model="username"
                        required>
                    </b-input>
                </b-field>

                <b-field :label="$t('password')">
                    <b-input
                        type="password"
                        password-reveal
                        :placeholder="$t('password')"
                        v-model="password"
                        required>
                    </b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-primary" @click="login()" type="button" v-bind:disabled="!canLogin">{{ $t('loginAction') }}
                </button>
            </footer>
        </div>
    </form>
</template>

<script>
import {HTTP} from '@/http-common'

export default {
    name: "login",
    data() {
        return {
            username: '',
            password: '',
            error: false
        }
    },
    computed: {
        canLogin() {
            return this.username !== '' && this.password !== ''
        }
    },
    methods: {
        login() {
            let body = {
                username: this.username,
                password: this.password
            };
            HTTP().post('login', body).then(response => {
                if (response.status === 200) {
                    this.$emit('success')
                }
            }).catch(() => {
                this.error = true;
            });
        }
    }
}
</script>

<style scoped>

</style>