<template>
    <div class="box">
        <b-field label="Bild">
            <image-uploader
                :debug="0"
                :maxWidth="1500"
                :maxHeight="1500"
                :quality="0.9"
                :autoRotate=false
                outputFormat="blob"
                :preview=true
                :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                :capture="false"
                accept="image/*"
                doNotResize="['gif', 'svg']"
                @input="setImage"
            >
            </image-uploader>
        </b-field>
        <b-field label="Beschreibung">
            <b-input v-model="description"/>
        </b-field>
        <div class="buttons">
            <b-button type="is-warning" @click="close()">
                {{ $t('cancel') }}
            </b-button>
            <b-button type="is-success" @click="save()">
                {{ $t('save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import {HTTP} from '../http-common'
import ImageUploader from 'vue-image-upload-resize'

export default {
    name: "AddStoneImage",
    props: ['stone_id'],
    components: {
        ImageUploader
    },
    data() {
        return {
            hasImage: false,
            image: false,
            description: ''
        }
    },
    methods: {
        setImage: function (output) {
            this.hasImage = true;
            this.image = output;
        },
        save() {
            let formData = new FormData();
            formData.append("image", this.image, "upload.jpeg");
            formData.append("stone", parseInt(this.stone_id));
            formData.append("description", this.description);

            HTTP().post('stones/image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.$emit('refresh');
                this.close();
            });
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style>
.img-preview {
    display: block;
}
</style>