/* eslint-disable */
import Vuex from "vuex";
import Vue from "vue";
import {ToastProgrammatic as Toast} from 'buefy'
import {milToCenti} from "./mixins"

Vue.use(Vuex)

const render_zpl = (id, type_name, type_id, date, height, width, thickness) => {
    let date_string = date.getDate() + "." + date.getMonth() + "." + date.getFullYear()
    return "^XA^FX^CF0,60^FO20,20^BY3^B3N,N,100,N,N^FD" +
        id + "^FS^FO20,130^ABN,10,10^FD" + type_name + "^FS^FO465,20^ACN,10,10^FDID: " + id +
        "^FS^FO465,45^ACN,10,10^FDSN: " + type_id + "^FS^FO465,70^ACN,10,10^FDDatum: " + date_string +
        "^FS^FO465,95^ACN,10,10^FD" + milToCenti(height) + " x " + milToCenti(width) + " x " +
        milToCenti(thickness) + "^FS^XZ"
}


export default new Vuex.Store({
    state: {
        printer: false
    },
    mutations: {
        SET_PRINTER(state, payload) {
            state.printer = payload
        }
    },
    actions: {
        connectDefaultPrinter(context) {
            BrowserPrint.getDefaultDevice("printer", printer => {
                context.commit('SET_PRINTER', printer)
            }, () => {
                Toast.open({
                    message: "fehler beim verbinden mit dem Drucker",
                    type: "is-danger"
                })
            })
        },
        printLabels(context, stones) {
            let zpl = ""
            stones.forEach(stone => {
                zpl += render_zpl(
                    stone.id,
                    stone.stone_type.name,
                    stone.stone_type.id,
                    new Date(stone.created),
                    stone.height,
                    stone.width,
                    stone.stone_type.thickness
                )
            })
            console.log(zpl)

            // send the generated zpl to the Printer
            context.state.printer.send(zpl, success => {
                Toast.open({
                    message: stones.length + " Labels erfolgreich zum Drucker gesendet",
                    type: "is-success"
                })
            }, error => {
                Toast.open({
                    message: "fehler beim Drucken",
                    type: "is-danger"
                })
            })
        }
    }
})