<template>
    <div class="container">
        <section class="section">
            <div id="livestream"></div>
        </section>
        <section class="section">
            <b-field>
                <b-input placeholder="ID" icon="magnify" v-model="newId" expanded></b-input>
                <p class="control">
                    <b-button type="is-primary" :label="$t('open')" @click="openId(newId)"/>
                </p>
            </b-field>
        </section>
    </div>
</template>

<script>
import Quagga from 'quagga';

export default {
    name: "ScanBarcode",
    data(){
      return {
          newId: null
      }
    },
    mounted() {

        let self = this;

        Quagga.init({
            inputStream: {
                name: "Live",
                type: "LiveStream",
                target: document.querySelector('#livestream')
            },
            decoder: {
                readers: ["code_39_reader"]
            }
        }, function (err) {
            if (err) {
                window.console.log(err);
                return
            }
            window.console.log("Initialization finished. Ready to start");
            Quagga.start();
            Quagga.onDetected(data => {
                let result = parseInt(data.codeResult.code);
                if (result) {
                    Quagga.stop();
                    self.openId(result);
                }
            })
        });
    },
    methods: {
        openId(id) {
            this.$router.push({
                name: 'stone-edit',
                params: {
                    stone_id: id
                }
            })
        }
    }
}
</script>

<style scoped>

</style>