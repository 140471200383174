import Vue from 'vue'
import VueI18n from 'vue-i18n'
import german from './german'

Vue.use(VueI18n);

const messages = {
    de: german
};

const dateTimeFormats = {
    'de': {
        default: {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }
    }
};

export default new VueI18n({
    locale: 'de',
    messages,
    dateTimeFormats
})